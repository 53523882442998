import React from 'react'
import { useAppDispatch, useTypedSelector } from 'store'
import { errorCleared, errorTypes, submitEstimate } from 'modules/customerInspectionView'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core'

function ErrorDialog() {
    const dispatch = useAppDispatch()
    const {
        error
    } = useTypedSelector(state => state.customerInspectionView)

    
    const handleClose = () => {
        dispatch(errorCleared())
    }
    
    let text = ``
    let buttons = <Button
        onClick={handleClose}
        color={`primary`}
        autoFocus
    >OK</Button>

    switch (error) {
        case errorTypes.NO_SIGNATURE:
            text = `A signature is required. Please sign the in the gray box and either accept or decline services.`
            break
        case errorTypes.UNHANDLED:
            text = `An error has occured. Please contact support if the problem persists.`
            break
        case errorTypes.DECLINE_SERVICES:
            text = `Are you sure you want to decline all recommended services?`
            buttons = <>
                <Button
                    onClick={handleClose}
                    color={`primary`}
                    autoFocus
                >Go Back</Button>
                <Button
                    onClick={() => dispatch(submitEstimate(`Declined`))}
                    color={`primary`}
                    autoFocus
                >Confirm</Button>
            </>
            break
    }

    return (
        <Dialog
            open={error !== errorTypes.NONE}
            onClose={handleClose}
        >
            <DialogContent>
                <DialogContentText>{text}</DialogContentText>
            </DialogContent>
            <DialogActions>{buttons}</DialogActions>
        </Dialog>
    )
}

export default ErrorDialog