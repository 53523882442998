import { createTheme } from '@material-ui/core/styles'

export default createTheme({
    palette: {
        primary: {
            main: `#337ab7`,
        },
        secondary: {
            main: `#f0ad4e`,
        }
    },
})