import React, { useState } from 'react'
import { 
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TableContainer,
} from '@material-ui/core'
import ArrowIconDown from '@material-ui/icons/KeyboardArrowDown'
import AcceptedIcon from '@material-ui/icons/CheckCircleOutline'
import DeclinedIcon from '@material-ui/icons/HighlightOff'
import BlankIcon from '@material-ui/icons/RadioButtonUnchecked'
import formatPrice from 'helpers/formatPrice'
import styles from './styles.module.scss'
import LineItemStatus from 'enums/lineItemStatus'
import LineItem from 'api/types/LineItem'

export interface ICategoryGroup {
    title: string
    items: LineItem[]
    color: string
    isOpenDefault?: boolean
}

function CategoryGroup({
    title,
    items,
    color,
    isOpenDefault = false,
}: ICategoryGroup) {
    const [isOpen, setIsOpen] = useState<boolean>(isOpenDefault)

    const handleAccordionChange = () => {
        setIsOpen(!isOpen)
    }

    const rows = items.map(item => {
        const ApprovedIcon = () => {

            return item.Status === LineItemStatus.ACCEPTED ? 
                <AcceptedIcon htmlColor={`#43a047`}/> :
                item.Status === LineItemStatus.DECLINED ?
                <DeclinedIcon htmlColor={`#e53935`}/> :
                <BlankIcon color={`disabled`}/>

        }

        return (
            <TableRow key={`line-item-${item.ID}`}>
                <TableCell>{item.OpCode}</TableCell>
                <TableCell>{item.Name}</TableCell>
                <TableCell align={`right`}>${formatPrice(item.Price)}</TableCell>
                <TableCell align={`center`}>{<ApprovedIcon/>}</TableCell>
            </TableRow>
        )
    })

    return (
        <Accordion 
            expanded={isOpen}
            onChange={handleAccordionChange}
            className={styles.container}
        >
            <AccordionSummary
                expandIcon={<ArrowIconDown  
                    className={styles.arrowButton}
                />}
                style={{
                    backgroundColor: color
                }}
                className={styles.summary}
            >
                <Typography 
                    noWrap
                    variant={`subtitle1`}
                >{title} ({items.length})</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <TableContainer>
                    <Table size={`small`}>
                        <TableHead>
                            <TableRow>
                                <TableCell>OpCode</TableCell>
                                <TableCell>Item</TableCell>
                                <TableCell align={`right`}>Price</TableCell>
                                <TableCell align={`center`}>Approved</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows}
                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionDetails>
        </Accordion>
    )
}

export default CategoryGroup