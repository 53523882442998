import {
    createEntityAdapter,
    createSlice,
    EntityState,
    PayloadAction,
    Update,
} from '@reduxjs/toolkit'
import { RootState } from 'rootReducer'
import { AppThunk } from 'store'
import apiFetch from 'api/apiFetch'
import UpdateEstimatePayload from 'api/types/UpdateEstimatePayload'
import MpviLineItem from 'types/MpviLineItem'
import apiMpviLineItem from 'api/types/MpviLineItem'
import modes from 'enums/appModes'
import { getEstimate, setMode } from 'modules/app'
import LineItemSeverity from 'enums/lineItemSeverity'
import LineItem from 'api/types/LineItem'

export enum errorTypes {
    NONE,
    NO_SIGNATURE,
    DECLINE_SERVICES,
    UNHANDLED,
}

interface IState {
    estimateID: number
    estimateKey: string
    isSubmitting: boolean
    signature: string
    error: errorTypes
    mpviLineItems: EntityState<MpviLineItem>
    lineItems: EntityState<LineItem>
}

export interface ILineItemsReceivedPayload {
    estimateID: number
    estimateKey: string
    signature: string
    mpviLineItems: MpviLineItem[]
    lineItems: LineItem[]
}

export const mpviLineItemsAdapter = createEntityAdapter<MpviLineItem>({
    selectId: (mpviLineItem) => mpviLineItem.LineItemID
})

export const lineItemsAdapter = createEntityAdapter<LineItem>({
    selectId: (lineItem) => lineItem.ID
})

const initialState: IState = {
    estimateID: 0,
    estimateKey: ``,
    isSubmitting: false,
    signature: ``,
    error: errorTypes.NONE,
    mpviLineItems: mpviLineItemsAdapter.getInitialState(),
    lineItems: lineItemsAdapter.getInitialState()
}

const slice = createSlice({
    name: `customerInspectionView`,
    initialState: initialState,
    reducers: {
        submitStarted(state) {
            state.isSubmitting = true
        },
        submitFinished(state) {
            state.isSubmitting = false
        },
        submitFailure(state) {
            state.isSubmitting = false
        },
        lineItemUpdated(state, action: PayloadAction<Update<MpviLineItem>>) {

            mpviLineItemsAdapter.updateOne(state.mpviLineItems, action.payload)

        },
        lineItemsUpdated(state, action: PayloadAction<Update<MpviLineItem>[]>) {

            mpviLineItemsAdapter.updateMany(state.mpviLineItems, action.payload)

        },
        lineItemsReceived(state, action: PayloadAction<ILineItemsReceivedPayload>) {
            const payload = action.payload
            state.estimateID = payload.estimateID
            state.estimateKey = payload.estimateKey
            state.signature = payload.signature
            mpviLineItemsAdapter.setAll(state.mpviLineItems, action.payload.mpviLineItems)
            lineItemsAdapter.setAll(state.lineItems, action.payload.lineItems)
        },
        signatureUpdated(state, action: PayloadAction<string>) {
            state.signature = action.payload
        },
        errorSet(state, action: PayloadAction<errorTypes>) {
            state.error = action.payload
        },
        errorCleared(state) {
            state.error = errorTypes.NONE
        }
    },
})

export const {
    submitStarted,
    lineItemUpdated,
    lineItemsUpdated,
    lineItemsReceived,
    signatureUpdated,
    errorSet,
    errorCleared,
} = slice.actions

export const mpviLineItemSelectors = mpviLineItemsAdapter.getSelectors<RootState>(state => state.customerInspectionView.mpviLineItems)
export const rawLineItemSelectors = lineItemsAdapter.getSelectors<RootState>(state => state.customerInspectionView.lineItems)

export default slice.reducer

export const submitEstimate = (action: `Unknown` | `Accepted` | `Declined`): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(submitStarted())
        const state = getState().customerInspectionView
        const items = mpviLineItemSelectors.selectAll(getState())
        const greenItems: apiMpviLineItem[] = []
        const yellowItems: apiMpviLineItem[] = []
        const redItems: apiMpviLineItem[] = []
        const additionalItems: apiMpviLineItem[] = []
        const initialWorkItems: apiMpviLineItem[] = []

        items.forEach(item => {

            switch (item.Severity) {
                case LineItemSeverity.SAFETY_CONCERN:
                    redItems.push(item)
                    break
                case LineItemSeverity.WARNING:
                    yellowItems.push(item)
                    break
                case LineItemSeverity.OK:
                    greenItems.push(item)
                    break
                case LineItemSeverity.ADDITIONAL:
                    additionalItems.push(item)
                    break
                case LineItemSeverity.INITIAL_WORK:
                    initialWorkItems.push(item)
                    break
            }
        })

        const payload: UpdateEstimatePayload = {
            ID: state.estimateID,
            EstimateKey: state.estimateKey,
            Action: action,
            Signature: state.signature,
            MPVI: {
                Green: greenItems,
                Yellow: yellowItems,
                Red: redItems,
                Additional: additionalItems,
                InitialWork: initialWorkItems
            }
        }

        await apiFetch(`api/mpvi/estimate/${state.estimateKey}`, {
            method: 'PUT',
            body: JSON.stringify(payload)
        })

        // load the latest estimate data into the state.
        dispatch(getEstimate(state.estimateKey))

        dispatch(setMode(modes.CONFIRMATION))

    } catch (error) {
        console.log(`hit`)
        dispatch(errorSet(errorTypes.UNHANDLED))
    }
}