import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import apiFetch from 'api/apiFetch'
import Estimate from 'api/types/Estimate'
import MpviLineItem from 'api/types/MpviLineItem'
import { RootState } from 'rootReducer'
import { AppThunk } from 'store'
import LineItemStatus from 'enums/lineItemStatus'
import LineItemSeverity from 'enums/lineItemSeverity'
import LineItem from 'api/types/LineItem'
import updateEstimateMessage from 'api/updateEstimate'

export enum errorTypes {
    NONE,
    LOAD_FAILURE,
    ADD_FAILURE,
    UPDATE_FAILURE,
    REMOVE_FAILURE,
    UPDATE_ESTIMATE_FAILURE
}

interface IState {
    isLoading: boolean
    error: errorTypes
}

export const lineItemsAdapter = createEntityAdapter<LineItem>({
    selectId: (lineItem) => lineItem.ID
})

const slice = createSlice({
    name: `advisorInspectionView`,
    initialState: lineItemsAdapter.getInitialState<IState>({
        isLoading: false,
        error: errorTypes.NONE,
    }),
    reducers: {
        getLineItemsStart(state) {
            state.error = errorTypes.NONE
            state.isLoading = true
        },
        getLineItemsFinish(state, action: PayloadAction<LineItem[]>) {
            state.isLoading = false
            lineItemsAdapter.setAll(state, action.payload)
        },
        getLineItemsFailure(state) {
            state.isLoading = false
            state.error = errorTypes.LOAD_FAILURE
        },
        lineItemAdded: lineItemsAdapter.addOne,
        addLineItemFailure(state) {
            state.error = errorTypes.ADD_FAILURE
        },
        lineItemUpdated: lineItemsAdapter.updateOne,
        updateLineItemFailure(state) {
            state.error = errorTypes.UPDATE_FAILURE
        },
        lineItemRemoved: lineItemsAdapter.removeOne,
        removeLineItemFailure(state) {
            state.error = errorTypes.REMOVE_FAILURE
        },
        updateEstimateMessageFailure(state) {
            state.error = errorTypes.UPDATE_ESTIMATE_FAILURE
        },
        errorCleared(state) {
            state.error = errorTypes.NONE
        }
    },
})

export const {
    getLineItemsStart,
    getLineItemsFinish,
    getLineItemsFailure,
    lineItemAdded,
    addLineItemFailure,
    lineItemUpdated,
    updateLineItemFailure,
    lineItemRemoved,
    removeLineItemFailure,
    updateEstimateMessageFailure,
    errorCleared,
} = slice.actions

export const lineItemsSelectors = lineItemsAdapter.getSelectors<RootState>(state => state.advisorInspectionView)

export default slice.reducer

// Thunks
export const getLineItems = (): AppThunk => async (dispatch, getState) => {
    try {
        const estimateKey = getState().app.estimateKey
        dispatch(getLineItemsStart())
        const res: Estimate = await apiFetch(`api/mpvi/estimate/${estimateKey}`)

        dispatch(getLineItemsFinish(res.LineItems))
    } catch (error) {
        dispatch(getLineItemsFailure())
    }   
}

export const createLineItem = (lineItem: Partial<LineItem>): AppThunk => async (dispatch, getState) => {
    try {
        const estimateKey = getState().app.estimateKey
        const res: MpviLineItem = await apiFetch(`api/mpvi/estimate/${estimateKey}/line-items`, {
            method: 'POST',
            body: JSON.stringify(lineItem),
        })
        dispatch(lineItemAdded({
            ID: res.LineItemID,
            Name: res.Name,
            OpCode: res.OpCode,
            Price: res.Price,
            Status: LineItemStatus.UNDECIDED,
            Severity: LineItemSeverity.ADDITIONAL,
        }))
    } catch (error) {
        dispatch(addLineItemFailure())
    }
}

export const updateLineItem = (lineItem: LineItem): AppThunk => async (dispatch, getState) => {
    try {
        const estimateKey = getState().app.estimateKey
        await apiFetch(`api/mpvi/estimate/${estimateKey}/line-items`, {
            method: 'PUT',
            body: JSON.stringify({
                LineItemID: lineItem.ID,
                Price: lineItem.Price,
                Name: lineItem.Name,
            })
        })
        dispatch(lineItemUpdated({
            id: lineItem.ID,
            changes: lineItem,
        }))
    } catch (error) {
        dispatch(updateLineItemFailure())
    }
}

export const deleteLineItem = (id: LineItem['ID']): AppThunk => async (dispatch, getState) => {
    try {
        const estimateKey = getState().app.estimateKey
        await apiFetch(`api/mpvi/estimate/${estimateKey}/line-items/${id}`, {
            method: 'DELETE',
        })
        dispatch(lineItemRemoved(id))
    } catch (error) {
        dispatch(removeLineItemFailure())
    }
}

export const updateEstimate = (message: string): AppThunk => async (dispatch, getState) => {

    try {

        const estimateKey = getState().app.estimateKey

        await updateEstimateMessage(estimateKey, message)

    } catch(error) {

        dispatch(updateEstimateMessageFailure())

    }

}