import React from 'react'

import {
    Box,
    IconButton,
    IconProps,
} from '@material-ui/core'
import { Add, Delete } from '@material-ui/icons'

export enum types {
    ADD,
    DELETE,
}

export interface IInspectionActionField {
    type: types
    disabled?: boolean
    onClick(): void
}

function InspectionActionField({
    type,
    disabled = false,
    onClick,
}: IInspectionActionField) {

    const Icon: IconProps = type === types.DELETE ? <Delete /> : <Add/>

    return (
        <Box marginTop={`6px`}>
            <IconButton 
                size={`small`}
                disabled={disabled}
                onClick={onClick}
            >
                {Icon}
            </IconButton>
        </Box>
    )
}

export default InspectionActionField