import { Button, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import SignatureCanvas from 'react-signature-canvas'
import { useAppDispatch } from "store"
import styles from './styles.module.scss'
import { signatureUpdated } from 'modules/customerInspectionView'

interface ISignature {
    signature: string
    signatureRequired: boolean
}

function SignatureContent({
    signature,
    signatureRequired
}: ISignature){

    const [hasInit, setHasInit] = useState<boolean>(false)
    const dispatch = useAppDispatch()

    let signatureCanvas

    useEffect(() => {

        if (!hasInit) {

            setHasInit(true)

            signatureCanvas.fromDataURL(signature)

            if(!signatureRequired) {

                signatureCanvas.off()

            }

        }

    }, [signatureCanvas, signature, hasInit, signatureRequired])

    const handleSignatureEnd = () => {

        dispatch(signatureUpdated(signatureCanvas.toDataURL()))

    }
    
    const handleSignatureClear = () => {

        signatureCanvas.clear()

        dispatch(signatureUpdated(``))

    }

    return (
        <React.Fragment>
            {signatureRequired && 
                <Typography className={styles.signatureHeader} variant='subtitle2'>
                    Sign Here
                </Typography>}
            
            <SignatureCanvas
                penColor={`rgb(40,66,131)`}
                backgroundColor={`#eee`}
                clearOnResize={false}
                ref={ref => signatureCanvas = ref}
                canvasProps={{
                    height: 150,
                    width: 280,
                }}
                onEnd={handleSignatureEnd}
            ></SignatureCanvas>

            {signatureRequired &&
                <Button
                    size={`small`}
                    color={`secondary`}
                    onClick={handleSignatureClear}
                >Clear Signature</Button>}
        </React.Fragment>
    )
}

export default SignatureContent