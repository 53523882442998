import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import {
    Box,
    Button,
    Card,
    CardContent,
    Typography,
} from '@material-ui/core'
import { videosSelectors } from 'modules/videos'
import { useAppDispatch, useTypedSelector } from 'store'
import { setMode } from 'modules/app'
import modes from 'enums/appModes'

function Video() {
    const dispatch = useAppDispatch()
    const videos = useTypedSelector(videosSelectors.selectAll).filter(video => video.Transcoded);
    const [index, setIndex] = useState<number>(0)

    const handleContinue = () => {
        if (index + 1 < videos.length) {
            setIndex(index + 1)
        } else {
            dispatch(setMode(modes.INSPECTION))
        }
    }

    const buttonText = index + 1 === videos.length ? `Continue` : `View Next Video`
    const videoText = videos.length > 1 ? `videos` : `a video`
    const video = videos[index]

    return (
        <>
            <Typography
                gutterBottom
            >Your service advisor has included {videoText} with your estimate.</Typography>
            <Card>
                <CardContent>
                    <Box
                        display={`grid`}
                        gridRowGap={10}
                    >
                        <Typography 
                            variant={`h5`}
                            align={`center`}
                        >Video ({index + 1} of {videos.length})</Typography>
                        <Box
                            position={`relative`}
                            pt={`56.25%`}
                        >
                            <ReactPlayer 
                                controls
                                url={[
                                    {src: `${process.env.REACT_APP_API_URL}/api/videos/${video.ID}/play?viewKey=${video.ViewKey}&format=.mp4`, type: `video/mp4`},
                                    {src: `${process.env.REACT_APP_API_URL}/api/videos/${video.ID}/play?viewKey=${video.ViewKey}&format=.webm`, type: `video/webm`}
                                ]}
                                width={`100%`}
                                height={`100%`}
                                style={{
                                    position: `absolute`,
                                    top: 0,
                                    left: 0,
                                }}
                            ></ReactPlayer>
                        </Box>
                        <Button
                            variant={`contained`}
                            color={`primary`}
                            onClick={handleContinue}
                        >{buttonText}</Button>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}

export default Video