import { Box, BoxProps, useTheme } from '@material-ui/core'
import React from 'react'

export interface IAppGrid {
    templateColumns?: string
    templateRows?: string
    justifyItems?: 'start' | 'end' | 'center' | 'stretch'
    alignItems?: 'start' | 'end' | 'center' | 'stretch'
    justifyContent?: 'start' | 'end' | 'center' | 'stretch' | 'space-around' | 'space-between' | 'space-evenly'
    alignContent?: 'start' | 'end' | 'center' | 'stretch' | 'space-around' | 'space-between' | 'space-evenly'
    rowSpacing?: number
    columnSpacing?: number
    gridAutoFlow?: 'row' | 'column' | 'row dense' | 'column dense'
    boxProps?: BoxProps
    children: React.ReactNode
}

function AppGrid({
    templateColumns,
    templateRows,
    justifyItems,
    alignItems,
    justifyContent,
    alignContent,
    rowSpacing,
    columnSpacing,
    gridAutoFlow,
    boxProps,
    children,
}: IAppGrid) {
    const theme = useTheme()
    const spacing = theme.spacing()

    return (
        <Box
            {...boxProps}
            style={{
                display: `grid`,
                gridTemplateColumns: templateColumns,
                gridTemplateRows: templateRows,
                justifyItems,
                alignItems,
                justifyContent,
                alignContent,
                rowGap: rowSpacing ? `${rowSpacing * spacing}px` : 0,
                columnGap: columnSpacing ? `${columnSpacing * spacing}px` : 0,
                gridAutoFlow,
            }}
        >{children}</Box>
    )
}

export default AppGrid