import React, { useEffect, useState } from 'react'
import {
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    Checkbox,
} from '@material-ui/core'
import MpviLineItem from 'types/MpviLineItem'
import { lineItemUpdated } from 'modules/customerInspectionView'
import { useAppDispatch } from 'store'
import formatPrice from 'helpers/formatPrice'

export interface IPricedView {
    items: MpviLineItem[]
    onTotalChange(total: number): void
    disableCheckboxes: boolean
}

function PricedView({
    items,
    onTotalChange,
    disableCheckboxes
}: IPricedView) {
    const dispatch = useAppDispatch()
    const [total, setTotal] = useState<number>(0.00)

    const onItemSelect = (item: MpviLineItem) => {
        dispatch(lineItemUpdated({
            id: item.LineItemID,
            changes: {
                Checked: !item.Checked
            }
        }))
    }

    useEffect(() => {
        const selectedItems = items.filter(item => item.Checked === true)
        const val = selectedItems.reduce(((value, item) => value + item.Price), 0)
        setTotal(val)
        onTotalChange(val)
    }, [items, onTotalChange])

    const rows = items.map(item => (
        <TableRow key={item.LineItemID}>
            <TableCell padding={`checkbox`} align={`center`}>
                <Checkbox
                    color={`default`}
                    size={`small`}
                    checked={item.Checked}
                    disabled={disableCheckboxes}
                    onChange={() => onItemSelect(item)}
                ></Checkbox>
            </TableCell>
            <TableCell>{item.Name}</TableCell>
            <TableCell align={`right`}>${formatPrice(item.Price)}</TableCell>
        </TableRow>
    ))

    return (
        <Table size={`small`}>
            <TableHead>
                <TableRow>
                    <TableCell padding={`checkbox`}>Approved</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell width={120} align={`right`}>Price</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows}
                <TableRow>
                    <TableCell rowSpan={2} />
                    <TableCell align={`right`}>Total</TableCell>
                    <TableCell align={`right`}>${formatPrice(total)}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default PricedView