import React, { useState } from 'react'
import {
    Box,
    InputAdornment,
    ListItemText,
    TextField,
} from '@material-ui/core'
import IconField from 'components/IconField'
import ActionField, { types } from 'components/InspectionActionField'
import { useAppDispatch } from 'store'
import { deleteLineItem, updateLineItem } from 'modules/advisorInspectionView'
import LineItemSeverity from 'enums/lineItemSeverity'
import LineItem from 'api/types/LineItem'
import formatPrice from 'helpers/formatPrice'

export interface IAdvisorLineItem {
    item: LineItem
}

function AdvisorLineItem({
    item,
}: IAdvisorLineItem) {
    const dispatch = useAppDispatch()
    const [price, setPrice] = useState<string>(formatPrice(item.Price))
    const [priceFieldHasError, setPriceFieldHasError] = useState<boolean>(false)
    const isAdditionalItem = item.Severity === LineItemSeverity.ADDITIONAL

    const handlePriceSubmit = () => {
        
        const newPrice = parseFloat(price)

        const priceChanged = item.Price !== newPrice

        if (!priceFieldHasError && priceChanged) {

            setPrice(formatPrice(newPrice))

            dispatch(updateLineItem({
                ...item,
                Price: newPrice,
            }))

        }
        
    }

    const handlePriceChange = (e: React.FocusEvent<HTMLInputElement>) => {
        
        const val = e.target.value
        
        const invalidPrice = isNaN(parseFloat(val))

        setPriceFieldHasError(invalidPrice)
        
        setPrice(val)

    }

    const handleDeleteItem = () => {
        dispatch(deleteLineItem(item.ID))
    }

    return (
        <>
            <IconField
                itemIconProps={{
                    type: item.Severity,
                }}
            ></IconField>
            <Box marginTop={`4px`}>
                <ListItemText primary={item.Name} />
            </Box>
            <TextField
                size={`small`}
                variant={`outlined`}
                value={price}
                label={`Price`}
                fullWidth
                InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                onChange={handlePriceChange}
                onBlur={handlePriceSubmit}
                error={priceFieldHasError}
            ></TextField>
            <ActionField
                type={types.DELETE}
                disabled={!isAdditionalItem}
                onClick={handleDeleteItem}
            ></ActionField>
        </>
    )
}

export default AdvisorLineItem