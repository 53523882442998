import {
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { AppThunk } from 'store'
import modes from 'enums/appModes'
import userModes from 'enums/userModes'
import { setDealer } from 'modules/dealer'
import { setUser } from 'modules/user'
import { setCustomer } from 'modules/customer'
import { setVehicle } from 'modules/vehicle'
import { videosReceived } from 'modules/videos'
import { lineItemsReceived } from 'modules/customerInspectionView'
import apiFetch from 'api/apiFetch'
import Estimate from 'api/types/Estimate'
import MpviLineItem from 'types/MpviLineItem'
import EstimateStatus from 'enums/estimateStatus'
import MPVIStatus from 'enums/mpviStatus'
import { estimateMessageReceived } from 'modules/estimateMessage'

interface IApp {
    mode: modes
    userMode: userModes
    estimateKey: string
    title: string
    loadingText: string
    errorText: string
}

interface IInitAppStartPayload {
    estimateKey: IApp['estimateKey']
    userMode: IApp['userMode']
}

interface IEstimateFinishedPayload {
    isEstimateCompleted: boolean,
    isEstimateSent: boolean
}

const initialState: IApp = {
    mode: modes.UNKNOWN,
    userMode: userModes.CUSTOMER,
    estimateKey: ``,
    title: `Vehicle Inspection Summary`,
    loadingText: ``,
    errorText: ``,
}

const slice = createSlice({
    name: `app`,
    initialState,
    reducers: {
        getEstimateStart(state) {
            state.mode = modes.LOADING
            state.loadingText = `Retrieving Estimate...`
        },
        getEstimateFinish(state, action: PayloadAction<IEstimateFinishedPayload>) {
            const {
                isEstimateCompleted,
                isEstimateSent
            } = action.payload

            state.mode = isEstimateCompleted ?
                    modes.REVIEW :
                    (state.userMode === userModes.CUSTOMER ?
                        modes.SUMMARY:
                        isEstimateSent ?
                            modes.REVIEW :
                            modes.INSPECTION)

            state.loadingText = initialState.loadingText
        },
        getEstimateFailure(state, action: PayloadAction<string>) {
            state.mode = modes.ERROR
            state.errorText = action.payload
        },
        initAppStart(state) {
            state.mode = modes.LOADING
            state.loadingText = `Intializing...`
        },
        initAppFinish(state, action: PayloadAction<IInitAppStartPayload>) {
            state.estimateKey = action.payload.estimateKey
            state.userMode = action.payload.userMode
            state.loadingText = initialState.loadingText
            state.mode = modes.UNKNOWN
        },
        initAppFailure(state, action: PayloadAction<string>) {
            state.errorText = action.payload
            state.mode = modes.ERROR
        },
        setMode(state, action: PayloadAction<modes>) {
            state.mode = action.payload
        },
        reset: () => initialState,
    },
})

export const {
    getEstimateStart,
    getEstimateFinish,
    getEstimateFailure,
    initAppStart,
    initAppFinish,
    initAppFailure,
    setMode,
    reset,
} = slice.actions

export default slice.reducer

export const getEstimate = (estimateKey: string): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(getEstimateStart())
        const res: Estimate = await apiFetch(`api/mpvi/estimate/${estimateKey}`)

        const userMode: userModes = getState().app.userMode
        const estimateCompleted = res.Status === EstimateStatus.CustomerApproved || res.Status === EstimateStatus.CustomerDeclined

        // if the estimate is not completed and the customer is opening the MPVI results, mark the estimate as `opened`.
        if (userMode === userModes.CUSTOMER && !estimateCompleted) {
            await apiFetch(`api/mpvi/estimate/${estimateKey}/opened`, {
                method: 'PUT'
            })
        }

        dispatch(setUser(res.User))

        dispatch(setCustomer(res.Customer))

        dispatch(setDealer(res.Dealer))

        dispatch(setVehicle(res.Vehicle))

        dispatch(videosReceived(res.Videos))

        dispatch(estimateMessageReceived(res.Message))

        const categories = [
            res.MPVI.Red,
            res.MPVI.Yellow,
            res.MPVI.Green,
            res.MPVI.Additional,
            res.MPVI.InitialWork
        ]
        const mpviLineItems: MpviLineItem[] = []

        categories.forEach((category, index) => category.forEach(item => {
            mpviLineItems.push({
                ...item,
                Severity: index,
            })
        }))

        dispatch(lineItemsReceived({
            estimateID: res.ID,
            estimateKey,
            mpviLineItems,
            lineItems: res.LineItems,
            signature: res.Signature,
        }))

        dispatch(getEstimateFinish({
            isEstimateCompleted: estimateCompleted,
            isEstimateSent: res.MPVIStatus > MPVIStatus.WaitingOnApproval
        }))

    } catch (error) {
        console.log(error)
        dispatch(getEstimateFailure(error.message))
    }
}