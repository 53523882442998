import React from 'react'
import ItemIcon, { IItemIcon } from 'components/ItemIcon'
import { Box } from '@material-ui/core'

export interface IIconField {
    itemIconProps: IItemIcon
}

function IconField({
    itemIconProps
}: IIconField) {
    return (
        <Box marginTop={`8px`}>
            <ItemIcon
                {...itemIconProps}
                showText={false}
            ></ItemIcon>
        </Box>
    )
}

export default IconField