import React, { useEffect, useMemo, useState } from 'react'
import {
    Typography,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
} from '@material-ui/core'
import InspectionCategory from './components/Category'
import { useAppDispatch, useTypedSelector } from 'store'
import PricedView from './components/PricedView'
import ListView, { LineItemCategory } from './components/ListView'
import { useSelector } from 'react-redux'
import { errorSet, errorTypes, mpviLineItemSelectors, submitEstimate } from 'modules/customerInspectionView'
import ErrorDialog from './components/ErrorDialog'
import LineItemSeverity from 'enums/lineItemSeverity'
import MpviLineItem from 'types/MpviLineItem'
import SignatureContent from 'components/SignatureContent'
import SubtotalContentTable from 'components/SubtotalContentTable'
import AppGrid from 'componentsUI/AppGrid'

function CustomerInspectionView() {
    const dispatch = useAppDispatch()
    const [subTotalSafetyConcern, setSubTotalSafetyConcern] = useState<number>(0.00)
    const [subTotalWarning, setSubTotalSafetyWarning] = useState<number>(0.00)
    const [subTotalAdditional, setSubTotalAdditional] = useState<number>(0.00)
    const [subTotal, setSubTotal] = useState<number>(0.00)
    const [taxCharged, setTaxCharged] = useState<number>(0.00)
    
    const lineItems = useSelector(mpviLineItemSelectors.selectAll)
    
    const signature = useTypedSelector(state => state.customerInspectionView.signature)

    const {
        TaxRate
    } = useTypedSelector(state => state.dealer)

    const safetyConcernItems = useMemo(() => (
        lineItems.filter(item => item.Severity === LineItemSeverity.SAFETY_CONCERN)
    ), [lineItems])
    const warningItems = useMemo(() => (
        lineItems.filter(item => item.Severity === LineItemSeverity.WARNING)
    ), [lineItems])
    const okItems = useMemo(() => (
        lineItems.filter(item => item.Severity === LineItemSeverity.OK)
    ), [lineItems])
    const additionalItems = useMemo(() => (
        lineItems.filter(item => item.Severity === LineItemSeverity.ADDITIONAL)
    ), [lineItems])
    const initialWorkItems = useMemo(() => (
        lineItems.filter(item => item.Severity === LineItemSeverity.INITIAL_WORK)
    ), [lineItems])

    const selectedItems = useMemo(() => lineItems.filter(item => item.Checked), [lineItems])

    const subTotalInitialWork: number = initialWorkItems.reduce((prev, current) => prev + current.Price, 0);

    const getCategories = (items: MpviLineItem[]): LineItemCategory[] => {
        const categories: LineItemCategory[] = []

        items.forEach((item, index) => {
            const categoryName = item.SectionName
            const categoryIndex = categories.findIndex(cat => cat.name === categoryName)

            if (categoryIndex !== -1) {
                categories[categoryIndex].lineItems.push(item)
            } else {
                categories.push({
                    id: index,
                    name: categoryName,
                    lineItems: [item]
                })
            }
        })

        return categories
    }

    const okItemCategories = useMemo(() => getCategories(okItems), [okItems])

    useEffect(() => {
        const sub = subTotalInitialWork + subTotalSafetyConcern + subTotalWarning + subTotalAdditional
        const tax = sub * TaxRate
        setSubTotal(sub)
        setTaxCharged(tax)
    }, [subTotalInitialWork, subTotalSafetyConcern, subTotalWarning, subTotalAdditional, TaxRate])

    const handleSubmit = () => {

        if (!signature) {

            dispatch(errorSet(errorTypes.NO_SIGNATURE))

        } else if (selectedItems.length === 0) {

            dispatch(errorSet(errorTypes.DECLINE_SERVICES))

        } else {

            dispatch(submitEstimate(`Accepted`))

        }
        
    }

    return (
        <>
            <Typography variant='h6'>Please select the services you would like to authorize, sign your name, and click Submit.</Typography>
            <Box mb={2}>
                <InspectionCategory
                    itemsCount={initialWorkItems.length}
                    type={LineItemSeverity.INITIAL_WORK}
                >
                    <PricedView
                        items={initialWorkItems}
                        onTotalChange={(_) => {}}
                        disableCheckboxes={true}
                    ></PricedView>
                </InspectionCategory>
                <InspectionCategory
                    itemsCount={safetyConcernItems.length}
                    type={LineItemSeverity.SAFETY_CONCERN}
                >
                    <PricedView
                        items={safetyConcernItems}
                        onTotalChange={setSubTotalSafetyConcern}
                        disableCheckboxes={false}
                    ></PricedView>
                </InspectionCategory>
                <InspectionCategory
                    itemsCount={warningItems.length}
                    type={LineItemSeverity.WARNING}
                >
                    <PricedView
                        items={warningItems}
                        onTotalChange={setSubTotalSafetyWarning}
                        disableCheckboxes={false}
                    ></PricedView>
                </InspectionCategory>
                <InspectionCategory
                    itemsCount={okItems.length}
                    type={LineItemSeverity.OK}
                    defaultExpanded={false}
                >
                    <ListView categories={okItemCategories || []} />
                </InspectionCategory>
                <InspectionCategory
                    itemsCount={additionalItems.length}
                    type={LineItemSeverity.ADDITIONAL}
                >
                    <PricedView
                        items={additionalItems}
                        onTotalChange={setSubTotalAdditional}
                        disableCheckboxes={false}
                    ></PricedView>
                </InspectionCategory>
                <Card>
                    <CardContent>
                        
                        <SubtotalContentTable
                            subtotal={subTotal}
                            tax={taxCharged}/>

                        <AppGrid
                            justifyContent={`center`}
                            boxProps={{
                                pt: 2,
                            }}
                        >

                            <SignatureContent
                                signature={signature}
                                signatureRequired={true} />
                            
                        </AppGrid>
                    </CardContent>
                    
                    <CardActions>
                        <Button
                            variant={`contained`}
                            color={`primary`}
                            fullWidth
                            onClick={handleSubmit}
                        >{selectedItems.length > 0 ? `Accept` : `Decline`}</Button>
                    </CardActions>
                </Card>
            </Box>
            <ErrorDialog/>
        </>
    )
}

export default CustomerInspectionView