import { Box, Card, CardContent, Typography } from "@material-ui/core"
import { rawLineItemSelectors } from "modules/customerInspectionView"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import CategoryGroup from "./components/CategoryGroup"
import LineItemSeverity from "enums/lineItemSeverity"
import LineItem from "api/types/LineItem"
import modes from "enums/appModes"
import userModes from "enums/userModes"
import { useTypedSelector } from "store"
import LineItemStatus from "enums/lineItemStatus"
import SignatureContent from "components/SignatureContent"
import SubtotalContentTable from "components/SubtotalContentTable"
import AppGrid from "componentsUI/AppGrid"

interface ICustomerSubmissionResultsView {
    view: modes,
    user: userModes
}

function CustomerSubmissionResultsView({
    view,
    user
}: ICustomerSubmissionResultsView) {

    const [subtotal, setSubtotal] = useState<number>(0.00)
    const [tax, setTax] = useState<number>(0.00)

    const lineItems = useSelector(rawLineItemSelectors.selectAll)

    const signature = useTypedSelector(state => state.customerInspectionView.signature)

    const {
        TaxRate
    } = useTypedSelector(state => state.dealer)

    const getLineItemsBySeverity = useCallback((severity: LineItemSeverity) => lineItems.filter(item => item.Severity === severity), [lineItems])

    const getSubtotal = useCallback(() => lineItems.reduce((prev, current) => {
        
        return current.Status === LineItemStatus.ACCEPTED ?
            prev + current.Price :
            prev

    }, 0), [lineItems])
    
    const safetyConcernItems: LineItem[] = getLineItemsBySeverity(LineItemSeverity.SAFETY_CONCERN)
    const warningItems: LineItem[] = getLineItemsBySeverity(LineItemSeverity.WARNING)
    const okItems: LineItem[] = getLineItemsBySeverity(LineItemSeverity.OK)
    const additionalItems: LineItem[] = getLineItemsBySeverity(LineItemSeverity.ADDITIONAL)
    const initialWorkItems: LineItem[] = getLineItemsBySeverity(LineItemSeverity.INITIAL_WORK)

    useEffect(() => {

        const subtotal = getSubtotal()

        setSubtotal(subtotal)

        setTax(subtotal * TaxRate)

    }, [TaxRate, getSubtotal])

    const header = useMemo(() => {

        if(view === modes.CONFIRMATION) {

            return (
                <React.Fragment>
                    <Typography variant="h6">
                        Thank you!
                    </Typography>
                    <Typography component="p">
                        Your approved and/or declined services have been successfully recorded. Your advisor will be in touch on the status of your vehicle.
                    </Typography>
                </React.Fragment>
            )

        }

        if (user === userModes.CUSTOMER) {

            return <Typography variant='h6'>Your signed estimate is available below for review.</Typography>

        }

    }, [view, user])

    return (
        <Box>
            {header}
            <CategoryGroup
                title={`Initial Work`}
                items={initialWorkItems}
                color={`#f5f5f5`}
                isOpenDefault={initialWorkItems.length > 0}
            ></CategoryGroup>
            <CategoryGroup
                title={`Safety Concerns`}
                items={safetyConcernItems}
                color={`#ffcdd2`}
                isOpenDefault={safetyConcernItems.length > 0}
            ></CategoryGroup>
            <CategoryGroup
                title={`Warnings`}
                items={warningItems}
                color={`#fff9c4`}
                isOpenDefault={warningItems.length > 0}
            ></CategoryGroup>
            <CategoryGroup
                title={`OK`}
                items={okItems}
                color={`#c8e6c9`}
            ></CategoryGroup>
            <CategoryGroup
                title={`Additional`}
                items={additionalItems}
                color={`#bbdefb`}
                isOpenDefault={additionalItems.length > 0}
            ></CategoryGroup>
            <Card>
                <CardContent>
                    
                    <SubtotalContentTable
                        subtotal={subtotal}
                        tax={tax}/>

                    <AppGrid
                        justifyContent={`center`}
                        boxProps={{
                            pt: 2,
                        }}
                    >

                        <SignatureContent
                            signature={signature}
                            signatureRequired={false} />
                        
                    </AppGrid>
                </CardContent>
            </Card>
        </Box>
    )
}

export default CustomerSubmissionResultsView