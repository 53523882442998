import React from 'react'
import AppLoadingIndicator from 'components/AppLoadingIndicator'
import useSubscribeToEstimate from 'hooks/useSubscribeToEstimate'
import ErrorView from 'components/ErrorView'
import { useTypedSelector } from 'store'
import {
    AppBar,
    Toolbar,
    Typography,
    Container,
    Box,
} from '@material-ui/core'
import modes from 'enums/appModes'
import Summary from 'components/Summary'
import InspectionView from 'components/InspectionView'
import Video from 'components/Video'
import CustomerSubmissionResultsView from 'components/CustomerSubmissionResultsView'

function BaseView() {
    const {
        title,
        mode,
        userMode
    } = useTypedSelector(
        state => state.app
    )

    useSubscribeToEstimate()

    let content

    switch (mode) {
        case modes.LOADING:
            content = <AppLoadingIndicator />
            break
        case modes.SUMMARY:
            content = <Summary />
            break
        case modes.VIDEO:
            content = <Video />
            break
        case modes.INSPECTION:
            content = <InspectionView />
            break
        case modes.ERROR:
            content = <ErrorView />
            break
        case modes.CONFIRMATION:
        case modes.REVIEW:
            content = <CustomerSubmissionResultsView view={mode} user={userMode}/>
            break
    }

    return (
        <Box>
            <Box marginBottom={2}>
                <AppBar position={`static`}>
                    <Toolbar>
                        <Typography variant={`h6`}>{title}</Typography>
                    </Toolbar>
                </AppBar>
            </Box>
            <Container>
                <Box>
                    {content}
                </Box>
            </Container>
        </Box>
    )
}

export default BaseView