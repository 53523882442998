import React from 'react'
import userModes from 'enums/userModes'
import { useTypedSelector } from 'store'
import AdvisorInspectionView from 'components/AdvisorInspectionView'
import CustomerInspectionView from 'components/CustomerInspectionView'
import AppGrid from 'componentsUI/AppGrid'

function InspectionView() {
    const { userMode } = useTypedSelector(state => state.app) 
    const message = useTypedSelector(state => state.estimateMessage.message)
    let content: React.ReactNode

    if (userMode === userModes.CUSTOMER) {
        content = <CustomerInspectionView/>
    } else {
        content = <AdvisorInspectionView estimateMessage={message}/>
    }

    return (
        <AppGrid
            rowSpacing={2}
            boxProps={{
                mb: 2
            }}
        >{content}</AppGrid>
    )
}

export default InspectionView