import React from 'react'
import CreateIcon from '@material-ui/icons/Create'
import CheckIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import WarningIcon from '@material-ui/icons/Warning'
import { blue, green, red, yellow, grey } from '@material-ui/core/colors'
import { Typography, SvgIcon, TypographyProps, } from '@material-ui/core'
import AppGrid from 'componentsUI/AppGrid'
import LineItemSeverity from 'enums/lineItemSeverity'

export interface IItemIcon {
    itemCount?: number
    type: LineItemSeverity
    textVariant?: TypographyProps['variant']
    showText?: boolean
}

function ItemIcon({
    itemCount = 0,
    type,
    textVariant = `body2`,
    showText = true,
}: IItemIcon) {
    let name: string = ``
    let color: string = ``
    let Icon: typeof SvgIcon | null = null
    const nameSuffix: string = itemCount > 1 ? `s` : ``

    switch (type) {
        case LineItemSeverity.INITIAL_WORK:
            name = `Initial Work Item`
            color = grey[900]
            Icon = CreateIcon
            break
        case LineItemSeverity.SAFETY_CONCERN:
            name = `Safety Concern`
            color = red[400]
            Icon = ErrorIcon
            break
        case LineItemSeverity.WARNING:
            name = `Warning`
            color = yellow[600]
            Icon = WarningIcon
            break
        case LineItemSeverity.OK:
            name = `OK Item`
            color = green[500]
            Icon = CheckIcon
            break
        case LineItemSeverity.ADDITIONAL:
            name = `Additional`
            color = blue[400]
            Icon = WarningIcon
            break
    }

    return (
        <AppGrid
            gridAutoFlow={`column`}
            columnSpacing={1}
            justifyContent={`start`}
            alignItems={`center`}
        >
            {Icon &&
                <Icon
                    htmlColor={color}
                />
            }
            {showText &&
                <Typography variant={textVariant}>
                    {itemCount} {name}{nameSuffix}
                </Typography>
            }
        </AppGrid>
    )
}

export default ItemIcon
