import React from 'react'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import ItemIcon from 'components/ItemIcon'
import LineItemSeverity from 'enums/lineItemSeverity'

export interface ICategory {
    itemsCount: number
    type: LineItemSeverity
    children: React.ReactNode
    defaultExpanded?: boolean
}

function Category({
    itemsCount,
    type,
    children,
    defaultExpanded = true,
}: ICategory) {
    return (
        <Accordion defaultExpanded={defaultExpanded}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
            >   
                <ItemIcon
                    type={type}
                    itemCount={itemsCount}
                ></ItemIcon>
            </AccordionSummary>
            <AccordionDetails>
               {children}
            </AccordionDetails>
        </Accordion>
    )
}

export default Category