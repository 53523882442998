import React from 'react'
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core'
import MpviLineItem from 'types/MpviLineItem'

export type LineItemCategory = {
    id: number,
    name: string,
    lineItems: MpviLineItem[],
}

export interface IListView {
    categories: LineItemCategory[]
}

function ListView({
    categories
}: IListView) {
    const content = categories.map(category => (
        <Table size={`small`} key={category.id}>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={2}>{category.name}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {category.lineItems.map((item, index) => (
                    <TableRow key={item.LineItemID}>
                        {index === 0 &&
                            <TableCell 
                                padding={`none`}
                                rowSpan={category.lineItems.length}
                                width={25}
                                style={{borderBottom: `none`}}
                            ></TableCell>
                        }
                        <TableCell>{item.Name}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    ))

    return (
        <Box width={`100%`}>{content}</Box>
    )
}

export default ListView