import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    TextField,
    Typography,
} from '@material-ui/core'
import { useAppDispatch } from 'store'
import AdvisorLineItem from './components/AdvisorLineItem'
import AppGrid from 'componentsUI/AppGrid'
import AddNewLineItem from './components/AddNewLineItem'
import { getLineItems, lineItemsSelectors, updateEstimate } from 'modules/advisorInspectionView'
import { useSelector } from 'react-redux'
import ErrorDialog from './components/ErrorDialog'
import LineItemSeverity from 'enums/lineItemSeverity'
import LineItem from 'api/types/LineItem'

interface IAdvisorInspectionView {
    estimateMessage: string
}

function AdvisorInspectionView({
    estimateMessage
}: IAdvisorInspectionView) {
    const [message, setMessage] = useState<string>(``)

    const dispatch = useAppDispatch()
    const lineItems = useSelector(lineItemsSelectors.selectAll)

    useEffect(() => {

        dispatch(getLineItems())

        if(estimateMessage !== null) {

            setMessage(estimateMessage)

        }

    }, [estimateMessage, dispatch])

    const initialWork: LineItem[] = []
    const safetyConcerns: LineItem[] = []
    const warnings: LineItem[] = []
    const additional: LineItem[] = []

    lineItems.forEach(item => {
        switch (item.Severity) {
            case LineItemSeverity.INITIAL_WORK:
                initialWork.push(item)
                break
            case LineItemSeverity.SAFETY_CONCERN:
                safetyConcerns.push(item)
                break
            case LineItemSeverity.WARNING:
                warnings.push(item)
                break
            case LineItemSeverity.ADDITIONAL:
                additional.push(item)
                break
        }
    })

    const items = [
        initialWork,
        safetyConcerns,
        warnings,
        additional
    ]

    const listItems = items.map(itemGroup => {
        return itemGroup.map(item => (
            <AdvisorLineItem
                key={item.ID}
                item={item}
            ></AdvisorLineItem>
        ))
    })

    const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        setMessage(event.target.value)

    }

    const handleMessageFieldLostFocus = (event: React.FocusEvent<HTMLInputElement>) => {

        dispatch(updateEstimate(message))
        
    }

    return (
        <>
            <Typography>Please review the following MPVI items and update any prices as needed.</Typography>

            <TextField
                fullWidth
                focused
                multiline={true}
                maxRows={3}
                variant='outlined'
                label={`Inspection Message`}
                placeholder={`Provide a custom message to send with the inspection summary.`}
                value={message}
                onChange={handleMessageChange}
                onBlur={handleMessageFieldLostFocus}/>

            <Card>
                <CardContent>
                    <AppGrid
                        columnSpacing={2}
                        rowSpacing={2}
                        templateColumns={`min-content 1fr 120px min-content`}
                        alignItems={`start`}
                        boxProps={{
                            width: `100%`
                        }}>
                        
                        {listItems}

                        <AddNewLineItem key={lineItems.length}/>

                    </AppGrid>

                </CardContent>
            </Card>
            <ErrorDialog/>
        </>
    )
}

export default AdvisorInspectionView